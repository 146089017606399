<template>
  <div style="margin-top: 20px">
    <el-card>
      <search-filter
          :searchName="'教案名称'"
          :keywordOptions="{placeholder:' '}"
          :searchOptions="whereOptions.filter(d=>['tid' , 'cid' , 'type'].indexOf(d.options.prop) > -1)"
          :otherOptions="whereOptions.filter(d=>['tid' , 'cid' , 'type'].indexOf(d.options.prop) === -1)"
          v-model="where"
          :total="total"
          @search="$refs.table.reset(...arguments)"
      ></search-filter>
       <div class="operate" >
      <el-button-group>
        <el-button :disabled="!$isPowers('teachPlanCourseCreate')" size="small" type="primary" @click="$handleRoute('teachPlanCourseCreate')">创建教案</el-button>
      </el-button-group>
    </div>

    </el-card>
      <com-table 
      style="margin-top: 30px;"
          ref="table"
          :data=" {
            url:'/xapi/course.course/myPlanList',
            params:{...$route.query}
          }"
          :columns="[
            {prop:'cover',label:'封面',width:'140' , align:'center',slot:'image'},
            {prop:'cid_name',label:'类别',width:'100' , align:'center'},
            {prop:'tid_name',label:'类型',width:'100' , align:'center'},
            {prop:'course_name',label:'名称',minWidth:'220px' ,slot: 'courseName' , align:'center'},
            {prop:'teach_name',label:'创建人' , align:'center'},
            {prop:'price',label:'普通使用权价格',width:'120' ,slot: 'price' , align:'center',},
            {prop:'price2',label:'高级使用权价格',width:'120' ,slot: 'price2' , align:'center'},
            {prop:'buy_num',label:'购买次数',width:'100' , align:'center'},
            {prop:'create_date',label:'创建时间',width:'160' , align:'center'},
            {prop:'valid',label:'是否有效',width:'120' , slot:'switch' , align:'center' ,callback:$isEditPower},
            // {prop:'is_publish',label:'是否发布',width:'120' , slot:'switch' , align:'center'},
            {fixed:'right',label:'操作',width:'180' , slot:'action' , align:'center'},
          ]"
          :page-size="12"
          :total.sync="total"
          :auto-load="false"
          :edit-method="handleEditChange"
      >
        <template slot="courseName" slot-scope="{row}" v-if="row.buy_type==2">
          <span v-for="tag,i in (row.tags || [])" :key="i" :style="{color:tag.color||null}">[{{tag.name}}]</span>
          <span v-html="row.course_name"></span>
        </template>
        <template slot="price" slot-scope="{row}" >
          <span>￥{{ row.price }}</span>
        </template>
        <template slot="price2" slot-scope="{row}" >
          <span>￥{{ row.price2 }}</span>
        </template>
        <el-button-group slot="action" slot-scope="{row}">
          <el-button
              @click="$handleRoute({course_id:row.id} , 'teachPlanCourseDetail')"
              type="text"
              size="small">查看</el-button>

          <!-- <el-button type="text" size="small"
                     @click="$handleRoute({course_id:row.id} , 'teachPlanCourseEdit')"
                     :disabled="!$isPowers('teachPlanCourseEdit') || row.user_id !== userInfo.id  || row.is_up==1"
          >编辑</el-button> -->
          <el-button type="text" size="small"
                     @click="$handleRoute({course_id:row.id} , 'teachPlanCourseEdit')"
                     :disabled="!$isPowers('teachPlanCourseEdit') || row.is_up==1"
          >编辑</el-button>

          <!-- <el-button @click="$handleConfirm('你确定删除此教案模板吗？','/xapi/course.course/delete',{id:row.id} , ()=>{$refs.table.reset();})"
                     type="text" size="small"
                     :disabled="!$isPowers('teachPlanCourseDel') || row.user_id !== userInfo.id || row.is_up==1"
          >删除</el-button> -->
          <el-button @click="$handleConfirm('你确定删除此教案吗？','/xapi/course.course/delete',{id:row.id} , ()=>{$refs.table.reset();})"
                     type="text" size="small"
                     :disabled="!$isPowers('teachPlanCourseDel') || row.is_up==1"
          >删除</el-button>

          <!-- <el-button type="text" size="small"
                     @click="$handleRoute({course_id:row.id,'$callback':'teachPlanCourseBuy'} , 'teachPlanCourseDetail')"
                     v-if="row.user_id !== userInfo.id && (row.up_types||[]).indexOf('2') > -1"
          >模式升级</el-button> -->
          <!-- {{(row.par_data && row.par_data.up_types.indexOf('1'))>0?'只是普通':'都有'}} -->
          <el-button type="text" size="small"
                     @click="$handleRoute({course_id:row.pid} , 'teachPlanCourseDetail')"
                     v-if="row.buy_type==1 && row.par_data && row.par_data.up_types && row.par_data.up_types.indexOf('2')>-1"
          >模式升级</el-button>
          <!-- <template v-if="((row.powers||{}).publish||[]).length > 0"> -->
          <template v-if="row.buy_type!=1">
            <el-button type="text" size="small"
                       :disabled="!$isPowers('teachPlanCourseUnder')"
                       @click="$handleConfirm('你确定取消发布此教案吗？','/xapi/course.course/underPlanCourse',{id:row.id} , ()=>{$refs.table.reset();})"
                       v-if="row.is_up === 1"
            >取消发布</el-button>
            <el-button :disabled="!$isPowers('teachPlanCourseUp') || row.valid==2" type="text" size="small" @click="$refs.isUpDialog.visibled(currentData = {...row})" v-else>发布</el-button>
          </template>
        </el-button-group>
      </com-table>

    <diy-dialog ref="isUpDialog" :width="'380px'" :title="(currentData.is_up === 1 ? '取消':'')+'发布提示'" center>
      <!-- <form-create ref="isUpForm" v-model="currentData" :components="[
            {name:'form-item',props:{prop:'up_types',required:true,label:(currentData.is_up === 1 ? '取消':'')+'发布类型',type:'checkbox',data:[{label:'普通使用许可',value:1,disabled:((currentData.powers||{}).publish||[]).indexOf(1) === -1},{label:'高级使用许可',value:2,disabled:((currentData.powers||{}).publish||[]).indexOf(2) === -1}]}},
            {name:'form-item',props:{prop:'price',disabled:false,label:'普通使用权价格'}},
            {name:'form-item',props:{prop:'price2',disabled:false,label:'高级使用权价格'}},
          ] " :label-width="'120px'">
      </form-create> -->
      <form-create ref="isUpForm" v-model="currentData" :components="[
            {name:'form-item',props:{prop:'up_types',required:true,label:(currentData.is_up === 1 ? '取消':'')+'发布类型',type:'checkbox',
            data:[{label:'普通使用许可',value:1,disabled:((currentData.powers||{}).publish||[]).indexOf(1) === -1},
            {label:'高级使用许可',value:2,disabled:currentData.buy_type==0?false:true}]}},
            {name:'form-item',props:{prop:'price',disabled:false,label:'普通使用权价格'}},
            {name:'form-item',props:{prop:'price2',disabled:currentData.buy_type==0?false:true,label:'高级使用权价格'}},
          ] " :label-width="'120px'">
      </form-create>
      <div slot="footer">
        <el-button style="margin-right: 50px;" type="primary" @click="$refs.isUpDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="$refs.isUpForm.validate((data,valid)=>{
        if(valid){
          handleCommand('confirm','确定'+(data.is_up === 1 ? '取消':'')+'发布教案【'+data.course_name+'】吗？' ,data.is_up === 1 ? '/xapi/course.course/underPlanCourse' : '/xapi/course.course/upPlanCourse',data,()=>{
            $refs.table.reset();$refs.isUpDialog.visibled(false)
          })
        }
      })">确 定</el-button>
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
export default {
  components: {SearchFilter},
  data(){
    return {
      where:{},
      whereOptions:[
        { name:'el-form-item' ,
          options:{label:'类型' , prop:'tid', type:'radio-group' , data:{ name:"course", keys:"type" } , defaultValue :'全部' }
        },
        { name:'el-form-item' ,
          options:{label:'类别' , prop:'cid', type:'checkbox'  , data:{ name:"course", keys:"cate" } , defaultValue :'全部'}
        },
        // { name:'el-form-item' ,
        //   options:{label:'种类' , prop:'type', type:'radio-group' , data:{ name:"course", keys:"class" } , defaultValue :'全部'}
        // },
        // { name:'el-form-item' ,
        //   options:{label:'时间' , prop:'date', type:'year' , placeholder:'请选择时间'}
        // },
      ],
      total:0,
      currentData:{},
    }
  },
  methods:{
    $isEditPower(props , {row}){
        // console.log(this.userInfo,"this.userInfo");
        // console.log(this.$isPowers('teachPlanCourseEdit'),"this.$isPowers('teachPlanCourseEdit')");
        // props.disabled = !this.$isPowers('teachPlanCourseEdit') || row.user_id !== this.userInfo.id
      props.disabled = !this.$isPowers('teachPlanCourseEdit') || (row.user_id == this.userInfo.id && row.is_up==1)
      // props.disabled =  row.is_up==1 
     
    },
    handleEditChange(v , ov , {row , column}){
      this.$http.post('/xapi/course.course/updateField',{
        field:column.prop,
        id:row.id,
        value:v
      }).then((res)=>{
        this.$http.post('/xapi/course.course/myPlanList' ,{...this.$route.query}).then((res)=>{
        // console.log(res,"resssssssssssss");
        }).finally(()=>{
          this.loading = false;
        });
        }).catch(()=>{
        row[column.prop] = ov;
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .table-box{
  .el-table .el-table__cell.table-cell-image
  , .el-table .el-table__cell.table-cell-image
  , .el-table .el-table__cell.table-cell-image.is-center{
    padding: 0 !important;
  }
}
::v-deep {
  .el-card__body {
    display: flex;
    justify-content: space-between;
    .classify {
      flex: 1;
    }
  }
}
</style>